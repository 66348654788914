import { VuexModule, Module } from 'vuex-class-modules';
import authModule from './AuthModule';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class PermissionModule extends VuexModule
{
    get activeUser()
    {
        return authModule.activeUser;
    }
    
    get hasPermission()
    {
        return (permission: string) => 
        {
            return this.activeUser?.roles.some(r =>
            {
                return permission == r._id;
            });
        };
    }

    get hasPermissions()
    {
        return (permissions: string[]) => 
        {
            return this.activeUser?.roles.some(r =>
            {
                return permissions.includes(r._id);
            });
        };
    }

    get isOwner()
    {
        return this.hasPermission('owner');
    }


    get canModifyAnyTickets()
    {
        return true;//this.isOwner || this.hasPermission('agent');
    }
}

// Register the module
import store from '../index';
const permissionsModule = new PermissionModule({store, name: 'permissions'});
export default permissionsModule;