import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class DataPromotionModule extends VuexModule
{
    @Action
    async getDatabases()
    {
        try
        {
            const resp = await axios.get<{ databases: string[] }>('databases');
            return resp.data.databases;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async dataPromotion(form: { sourceDbId: string; collections: string[]; targetDbId: string })
    {
        const resp = await axios.post(
            `database/${form.sourceDbId}/datapromotion/${form.targetDbId}`,
            { collections: form.collections },
        );
        return resp.data;
    }
}

// Register the module
import store from '../index';
const dataPromotionModule = new DataPromotionModule({store, name: 'datapromotion'});
export default dataPromotionModule;