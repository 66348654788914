
import { Component, Vue } from 'vue-property-decorator';
import deepcopy from '@/store/modules/DeepCopyModule';

@Component
export default class Home extends Vue
{
    databaseOptions: string[] = [];
    databaseGames: { _id: string; name: string; updated: Date }[] = [];

    form = {
        sourceDbId: '',
        gameId: '',
        targetDbId: ''
    }

    loading = false;
    copying = false;

    get formValid()
    {
        return this.form.sourceDbId.length > 0
            && this.form.gameId.length > 0
            && this.form.targetDbId.length > 0;
    }

    get gameOptions()
    {
        return this.databaseGames.map((g) =>
        {
            return { 
                value: g._id,
                text: `${g.name} (${g.updated})`
            };
        }).sort((a, b) =>
        {
            return a.text.localeCompare(b.text);
        });
    }

    async getDatabases() 
    {
        this.loading = true;
        try
        {
            this.databaseOptions = await deepcopy.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async getGames() 
    {
        this.loading = true;
        this.form.gameId = '';
        try
        {
            this.databaseGames = await deepcopy.getDatabaseGames(this.form.sourceDbId);
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Cannot load game list for the selected database!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async copy() 
    {
        this.copying = true;
        try
        {
            await deepcopy.deepCopyGame(this.form);
            this.$bvToast.toast(
                `Copied the selected game from ${this.form.sourceDbId} to ${this.form.targetDbId}`,
                {
                    title: 'Copy completed!',
                    autoHideDelay: 9000,
                    variant: 'success'
                }
            );
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Copy failed!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.copying = false;
        }
    }

    mounted()
    {
        this.getDatabases();
    }
}
