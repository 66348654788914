
import { Component, Vue, Watch } from 'vue-property-decorator';
import User from '@/models/User';
import UserManage from './UserManage.vue';
import userModule from '@/store/modules/UserModule';

type UserListRow = User & { sourceDb: string };

@Component({components: {UserManage}})
export default class UserManagement extends Vue
{
    rawDatabaseOptions: string[] = [];
    get databaseOptions()
    {
        return this.rawDatabaseOptions.map(a=>
        {
            // Kinda gross override, but AJ wanted it to display as SCS for demoing purposes and
            // this is by _far_ the most straightforward way to support that.
            // if (a === 'aus')
            // {
            //     return { value: a, text: 'SCS' };
            // }
            return a;
        });
    }
    
    // singleSourceDbId = '';
    selectedDatabases: string[] = [];
    
    @Watch('selectedDatabases')
    async onSelectedDatabaseChanged()
    {
        await this.refresh();
    }
    
    loading = false;
    
    tab = 0;
    filter = '';
    stateFilter = '';
    sourceUrl = '/unresolved';

    @Watch('sourceUrl')
    async onSourceUrlChanged()
    {
        await this.refresh();
    }

    userList: UserListRow[] = [];

    showUser = false;

    selectedUserId?: string | null = null;
    selectedUserSourceDb?: string | null = null;

    async getDatabases() 
    {
        this.loading = true;
        try
        {
            this.rawDatabaseOptions = await userModule.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.',
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async getUsers(): Promise<UserListRow[]>
    {
        const users: UserListRow[] = [];

        const waiters: Promise<void>[] = [];
        for (const db of this.selectedDatabases)
        {
            const promise = async ()=>
            {
                const dbUsers = await userModule.getUsers({dbIdentifier: db});
                users.push(...dbUsers.map(a=>({...a, sourceDb: db})));
            };
            waiters.push(promise());
        }
        await Promise.all(waiters);

        return users;
    }

    get tableFields()
    {
        const fields = [
            // {
            //     key: '_id',
            //     label: 'ID',
            //     sortable: true,
            // },
            {
                key: 'name',
                label: 'Name',
                sortable: true,
                sortByFormatted: true,
                formatter: (value: string, key: string, item: UserListRow) =>
                {
                    return item ? `${item.firstName} ${item.lastName}`.trim() : '[ERROR]';
                },
                thStyle:
                {
                    width: '8%', // add the fixed param to the b-table to enable these fixed percentage widths
                },
            },
            {
                key: 'email',
                label: 'Email',
                sortable: true,
                formatter: (value: string, key: string, item: UserListRow) =>
                {
                    return value ? value : (item ? item.email : '');
                },
                thStyle:
                {
                    width: '13%',
                },
                tdClass: 'text-left',
            },
            {
                key: 'sourceDb',
                label: 'Environment',
                sortable: true,
                formatter: (value: { _id: string }[], key: string, item: UserListRow) =>
                {
                    return item.sourceDb;
                },
                sortByFormatted: true,
                thStyle:
                {
                    width: '13%',
                },
                tdClass: 'text-center',
            },
            {
                key: 'roles',
                label: 'Roles',
                sortable: true,
                formatter: (value: { _id: string }[]) =>
                {
                    // Turn into a comma separated list of the IDs
                    const roles = value.map(a=>a._id);
                    roles.sort();
                    return roles.join(', ');
                },
                sortByFormatted: true,
                thStyle:
                {
                    width: '13%',
                },
                tdClass: 'text-left',
            },
            {
                key: 'lastActive',
                sortable: true,
                sortByFormatted: false,
                formatter: (value: string, key: string, item: UserListRow) =>
                {
                    return this.computeDateString(item.lastActive);
                },
                thStyle:
                {
                    width: '7.5%',
                },
            }
        ];

        // if (this.multiSelect)
        // {
        //     fields.push({key: 'selected', sortable: false});
        // }

        return fields;
    }

    static convertDateToLocaleString(date: Date | string)
    {
        if (!date) return 'Never';

        if (typeof date === 'string')
        {
            if (date.endsWith('Z')) return new Date(date).toLocaleString();
            if (date.includes('+')) return new Date(date).toLocaleString();
            return new Date(date + '+0000').toLocaleString();
        }

        return date.toLocaleString();
    }

    computeDateString(date: Date)
    {
        return UserManagement.convertDateToLocaleString(date);
    }

    rowClicked(item: UserListRow)
    {
        // if (this.multiSelect) return;
        // this.$router.push(`/tickets/${item.id}`);
        console.log(item);
        this.selectedUserId = item._id;
        this.selectedUserSourceDb = item.sourceDb;
        this.showUser = true;
    }

    get formattedTickets()
    {
        // Added this check to solve reactivity bug on refresh
        if (this.loading) return [];

        let intermediate = this.userList;

        const trimmed = this.filter.trim().toLowerCase();
        if (trimmed !== '')
        {
            intermediate = intermediate.filter((a) =>
                (a && a.email.toLowerCase().includes(trimmed))
                || (a && `${a.firstName} ${a.lastName}`.toLowerCase().includes(trimmed))
            );
        }

        return intermediate.map((a) =>
        {
            const temp =
                Object.assign(
                    {
                        // _rowVariant: (Ticket.isClosed(a)) ? 'success' : (Ticket.isInProgress(a) ? 'warning' : 'danger'),
                        selected: false
                    }, a);
            return temp;
        });
    }

    async refresh()
    {
        if (this.loading) return;

        this.userList.length = 0;
        this.loading = true;
        try
        {
            // Load users
            const userList = await this.getUsers();
            this.userList.push(...userList);
        }
        catch (err)
        {
            alert(`Failed to load users`);
        }
        this.loading = false;
    }

    mounted()
    {
        this.getDatabases();
    }
}
