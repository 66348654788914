import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';

export type TicketAssociatedEmail = {
    id: string;
    from: string;
    message: string;
    hideInReplies: boolean;
    date: Date | string;
};

export class Ticket
{
    _id = '';
    ticketId = -1;
    subject = '';
    description = '';
    creator = {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
    };
    
    // On the client, it can either be populated (for the list) or just the ID (for the ticket page)
    assignee: { _id: string; firstName: string; lastName: string; email: string } | '' | null = null;

    contactEmail = '';
    createdFromEmail = false;

    // Support emails
    associatedEmails: TicketAssociatedEmail[] = [];

    // Internal notes for the support team
    notes: {
        text?: string | null;
        author: {
            firstName: string;
            lastName: string;
            email: string;
        };
        date: Date;
    }[] = [];

    // Data captured by the client
    url = '';
    vuex = '';
    imageUrl = '';

    history: {
        status: string;
        changedBy: {
            firstName: string;
            lastName: string;
            email: string;
        };
        date: Date;
    }[] = [];

    externalSource?: string | null;
    externalId?: string | null;

    updated = new Date;
    created = new Date;

    // it compiles (erroneously), but in order to get any of these getters to
    // appear on what axios returns with the help of typescript, you'd have to
    // do a new Ticket(ticket) call for each ticket. this isn't too
    // inefficient for single-ticket endpoints, but anything that returns an
    // array of Ticket isn't going to work. so this kind of logic needs
    // to be static.

    // // constructor

    // constructor(payload: Ticket)
    // {
    //     this._id = payload._id;
    //     this.description = payload.description;
    //     this.creator = payload.creator;
    //     this.contactEmail = payload.contactEmail;
    //     this.notes = payload.notes;
    //     this.url = payload.url;
    //     this.vuex = payload.vuex;
    //     this.imageUrl = payload.imageUrl;
    //     this.history = payload.history;
    //     this.updated = payload.updated;
    // }

    // // convenience getters

    // get status()
    // {
    //     return (this.history && this.history.length > 0)
    //         ? (this.history[this.history.length - 1].status)
    //         : 'invalid';
    // }

    // get isClosed()
    // {
    //     return this.status === 'closed';
    // }

    // get isInProgress()
    // {
    //     return this.status === 'in progress';
    // }

    // get isOpen()
    // {
    //     return this.status === 'open';
    // }

    static status(ticket?: Ticket | null)
    {
        return (ticket && ticket.history && ticket.history.length > 0)
            ? (ticket.history[ticket.history.length - 1].status)
            : 'invalid';
    }

    static isClosed(ticket?: Ticket | null)
    {
        return Ticket.status(ticket) === 'closed';
    }

    static isMarkedForDeletion(ticket?: Ticket | null)
    {
        return Ticket.status(ticket) === 'marked for deletion';
    }

    static getDeletionTime(ticket? : Ticket | null)
    {
        if (ticket && ticket.history && ticket.history.length > 0)
        {
            const lastChange = ticket.history[ticket.history.length - 1];
            const date = new Date(lastChange.date);
            // Add 24 hr
            date.setDate(date.getDate() + 1);
            return date;
        }
        return null;
    }

    static isInProgress(ticket?: Ticket | null)
    {
        return Ticket.status(ticket) === 'in progress';
    }

    static isOpen(ticket?: Ticket | null)
    {
        return Ticket.status(ticket) === 'open';
    }

    static userToText(user?: { firstName: string; lastName: string; email: string } | null)
    {
        return user ? `${user.firstName} ${user.lastName} (${user.email})` : '[no user]';
    }

    static ticketDisplayId(ticket: Ticket)
    {
        const id = ticket.ticketId;
        const created = ticket.created ? new Date(ticket.created) : null;
        return (id && created) ? `${created.getSeconds().toString().padStart(2, '0')}-${created.getMilliseconds().toString().padStart(3, '0')}-${id}` : 'Unset';
    }

    static convertDateToLocaleString(date: Date | string)
    {
        if (typeof date === 'string')
        {
            if (date.endsWith('Z')) return new Date(date).toLocaleString();
            if (date.includes('+')) return new Date(date).toLocaleString();
            return new Date(date + '+0000').toLocaleString();
        }

        return date.toLocaleString();
    }
}

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class SupportModule extends VuexModule
{
    @Action
    async getDatabases()
    {
        try
        {
            const resp = await axios.get<{ databases: { name: string; visible: boolean }[] }>('support/databases');
            return resp.data.databases;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getAgents(request: { dbIdentifier: string })
    {
        try
        {
            const resp = await axios.get<{ agents: { _id: string; firstName: string; lastName: string; email: string }[] }>(`database/${request.dbIdentifier}/agents`);
            return resp.data.agents;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getDatabaseTickets(request: {onlyShowAssigned: boolean; dbIdentifier: string; modifier: string; stateFilter?: string })
    {
        try
        {
            let endpoint = `database/${request.dbIdentifier}/supporttickets${request.modifier}`;
            let hasQuery = false;
            if (request.stateFilter)
            {
                endpoint += `?stateFilter=${encodeURIComponent(request.stateFilter)}`;
                hasQuery = true;
            }

            if (request.onlyShowAssigned)
            {
                endpoint += hasQuery ? '&' : '?';
                endpoint += 'onlyShowAssigned=true';
            }

            const resp = await axios.get<{ tickets: Ticket[] }>(endpoint);
            return resp.data.tickets;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getDatabaseTicketSources(request: { dbIdentifier: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticketsources`;
            const resp = await axios.get<{ sources: string[] }>(endpoint);
            return resp.data.sources;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getDatabaseTicket(request: { dbIdentifier: string; _id: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}`;
            const resp = await axios.get<{ ticket: Ticket }>(endpoint);
            return resp.data.ticket;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async deleteDatabaseTicket(request: { dbIdentifier: string; _id: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}`;
            await axios.delete<{ ticket: Ticket }>(endpoint);
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async setTicketStatus(request: { dbIdentifier: string; _id: string; status: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/status`;
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { status: request.status });
            return resp.data.ticket ? resp.data.ticket.history : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async sendTicketMessage(request: { dbIdentifier: string; _id: string; message: string; attachments: File[] })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/message`;

            const formData = new FormData();
            formData.append('message', request.message);
            request.attachments.forEach((file, index) =>
            {
                console.log(`\\\\\\file ${index} below`);
                console.log(file);
                console.log(`///file ${index} above`);
                formData.append(`attachment${index}`, file);
            });
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return resp.data.ticket ? resp.data.ticket.associatedEmails : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async addTicketNotes(request: { dbIdentifier: string; _id: string; notes: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/notes`;
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { notes: request.notes });
            return resp.data.ticket ? resp.data.ticket.notes : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async updateTicketAssignment(request: { dbIdentifier: string; _id: string; assignee: string | null })
    {
        const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/assignee`;
        const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { assignee: request.assignee });
        return resp.data.ticket ? resp.data.ticket.assignee : null;
    }

    @Action
    async updateDescription(request: { dbIdentifier: string; _id: string; description: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/description`;
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { description: request.description });
            return resp.data.ticket ? resp.data.ticket.contactEmail : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async updateSubject(request: { dbIdentifier: string; _id: string; subject: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/subject`;
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { subject: request.subject });
            return resp.data.ticket ? resp.data.ticket.contactEmail : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async updateContactEmail(request: { dbIdentifier: string; _id: string; contactEmail: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/contactemail`;
            const resp = await axios.patch<{ ticket: Ticket }>(endpoint, { contactEmail: request.contactEmail });
            return resp.data.ticket ? resp.data.ticket.contactEmail : null;
        }
        catch (err)
        {
            console.error(err);
            return null;
        }
    }

    @Action
    async moveTicket(request: { dbIdentifier: string; _id: string; targetDbIdentifier: string })
    {
        try
        {
            const endpoint = `database/${request.dbIdentifier}/supportticket/${request._id}/move`;
            const resp = await axios.post<{ ticket: Ticket }>(endpoint, { targetDbIdentifier: request.targetDbIdentifier });
            return resp.data.ticket ? resp.data.ticket : null;
        }
        catch(err)
        {
            console.error(err);
            return null;
        }
    }
}

// Register the module
import store from '../index';
const supportModule = new SupportModule({store, name: 'support'});
export default supportModule;