import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DefaultContainer from '@/views/containers/DefaultContainer.vue';
import Home from '@/views/main/Home.vue';
import SupportTicket from '@/components/SupportTicket.vue';

//import GameContainer from '@/containers/GameContainer.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'root',
        component: DefaultContainer,
        children: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: '/ticket',
                name: 'Support Ticket',
                component: SupportTicket,
                props: route => ({ dbid: route.query.dbid, id: route.query.id }),
            },
        ]
    },
    {
        path: '*',
        redirect: '/'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;