import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';
import User from '@/models/User';

export type TicketAssociatedEmail = {
    id: string;
    from: string;
    message: string;
    hideInReplies: boolean;
    date: Date | string;
};

type ObjectID = string;

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class UserModule extends VuexModule 
{
    @Action
    async getDatabases() 
    {
        try 
        {
            const resp = await axios.get<{ databases: string[] }>('databases');
            return resp.data.databases;
        }
        catch (err) 
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getUsers(request: { dbIdentifier: string })
    {
        const endpoint = `database/${request.dbIdentifier}/users`;
        const resp = await axios.get<{ users: User[] }>(endpoint);
        return resp.data.users;
    }

    @Action
    async getUser(request: { dbIdentifier: string; userId: ObjectID })
    {
        const endpoint = `database/${request.dbIdentifier}/user/${request.userId}`;
        const resp = await axios.get<{ user: User }>(endpoint);
        return resp.data.user;
    }

    @Action
    async copyUser(request: { sourceDbId: string;  targetDbId: string; userId: ObjectID })
    {
        const endpoint = `database/${request.sourceDbId}/user/${request.userId}/copy/${request.targetDbId}`;
        const resp = await axios.post<{ user: User }>(endpoint);
        return resp.data.user;
    }

    @Action
    async deleteUser(request: { dbIdentifier: string; userId: ObjectID })
    {
        const endpoint = `database/${request.dbIdentifier}/user/${request.userId}`;
        const resp = await axios.delete<{ user: User }>(endpoint);
        return resp.data.user;
    }
}

// Register the module
import store from '../index';
const userModule = new UserModule({ store, name: 'users' });
export default userModule;