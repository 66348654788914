
import { Component, Vue } from 'vue-property-decorator';
import dataPromotion from '@/store/modules/DataPromotionModule';

@Component
export default class DataPromotion extends Vue
{
    databaseOptions: string[] = [];
    collectionOptions = [
        { text: 'Classes/Workgroups', value: 'classes', },
        { text: 'Coupons', value: 'coupons', },
        { text: 'Game Sets', value: 'gamesets', },
        { text: 'Registration Links', value: 'registrationlinks', },
        { text: 'Report Configs', value: 'reportconfigs', },
        { text: 'Signin Brandings', value: 'signinconfigs', },
        { text: 'Gamulations', value: 'games', },
        { text: 'Gamulation Brandings', value: 'environments', },
        { text: 'Sections', value: 'challenges', },
        { text: 'Major Decisions', value: 'teamdecisions', },
        { text: 'Minor Decisions', value: 'individualdecisions', },
        { text: 'Roles', value: 'roles', },
        { text: 'Metrics', value: 'metrics', },
        { text: 'Characters', value: 'characters', },
        { text: 'Endgame Settings', value: 'outros', },
    ];

    form = {
        sourceDbId: '',
        collections: [] as string[],
        targetDbId: ''
    }

    loading = false;
    copying = false;

    get formValid()
    {
        return this.form.sourceDbId.length > 0
            && this.form.collections.length > 0
            && this.form.targetDbId.length > 0;
    }

    async getDatabases() 
    {
        this.loading = true;
        try
        {
            this.databaseOptions = await dataPromotion.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async copy() 
    {
        if (!window.confirm(`Are you sure you want to promote data from ${this.form.sourceDbId} to ${this.form.targetDbId}?`)) return;

        this.copying = true;
        try
        {
            await dataPromotion.dataPromotion(this.form);
            this.$bvToast.toast(
                `Promoted data from ${this.form.sourceDbId} to ${this.form.targetDbId}`,
                {
                    title: 'Data promotion completed!',
                    autoHideDelay: 9000,
                    variant: 'success'
                }
            );
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Data promotion failed!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.copying = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClickSelectAllCollections(ev?: Event)
    {
        while (this.form.collections.length) this.form.collections.pop();
        this.collectionOptions.forEach(o => this.form.collections.push(o.value));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClickDeselectAllCollections(ev?: Event)
    {
        while (this.form.collections.length) this.form.collections.pop();
    }

    mounted()
    {
        this.onClickSelectAllCollections();
        this.getDatabases();
    }
}
