
import { Component, Vue, Watch } from 'vue-property-decorator';
import GameDeepCopy from '@/components/GameDeepCopy.vue';
import CharacterCopy from '@/components/CharacterCopy.vue';
import UserManagement from '@/components/UserManagementList.vue';
import DataPromotion from '@/components/DataPromotion.vue';
import SupportTickets from '@/components/SupportTickets.vue';
import permissionsModule from '@/store/modules/PermissionsModule';
import authModule from '@/store/modules/AuthModule';

@Component({
    components: {
        GameDeepCopy,
        CharacterCopy,
        DataPromotion,
        SupportTickets,
        UserManagement,
    }
})
export default class Home extends Vue
{
    selectedTab = 0;

    get permissions()
    {
        return permissionsModule;
    }

    mounted()
    {
        if (this.$route.query.tab)
        {
            const tab = this.$route.query.tab as string;
            if (tab)
            {
                const tabIndex = Number.parseInt(tab);
                this.selectedTab = tabIndex;
            }
        }
    }

    logout()
    {
        authModule.logout();
    }

    @Watch('selectedTab')
    onSelectedTabChanged(newValue: number)
    {
        if (!(this.$route.query.tab) || this.$route.query.tab !== `${newValue}`)
        {
            this.$router.push({ path: '', query: { tab: `${newValue}` }});
        }
    }
}
