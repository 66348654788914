
import Vue from 'vue';
import Component from 'vue-class-component';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Emit, Prop, Watch } from 'vue-property-decorator';
// import { AxiosResponse } from 'axios';

enum TicketReplyEditorSlotType
{
    Text,
    Image
}

class TicketReplyEditorSlot
{
    value = '';
    type: TicketReplyEditorSlotType = TicketReplyEditorSlotType.Text;

    contentEditable()
    {
        return this.type === TicketReplyEditorSlotType.Text;
    }

    toEditorHtml()
    {
        switch (this.type)
        {
        case TicketReplyEditorSlotType.Text:
            return `${this.value}`;
        case TicketReplyEditorSlotType.Image:
            return `<img src="${this.value}" />`;
        }
    }

    toOutputHtml()
    {
        switch (this.type)
        {
        case TicketReplyEditorSlotType.Text:
            return `<div>${this.value}</div>`;
        case TicketReplyEditorSlotType.Image:
            return `<img src="${this.value}" />`;
        }
    }
}

@Component
export default class SupportTicketReplyEditor extends Vue
{
    @Prop() public value!: string;
    @Emit('input') public emitInput()
    { 
        return this.html;
    }

    divToSlotIndex(div: EventTarget | HTMLElement | null | undefined)
    {
        if (!div) return -1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Array.from(this.$refs['editor-slot'] as any).indexOf(div);
    }

    async handleTextInput(input: InputEvent)
    {
        try
        {
        // If enter was pressed, either select the next slot or create a new one
            if (input.inputType === 'insertParagraph')
            {
                const idx = this.divToSlotIndex(input.target);
                if (idx === -1) return;

                const slot = this.slots[idx];
                if (slot.type === TicketReplyEditorSlotType.Text)
                {
                    const target = input.target as HTMLDivElement;
                    const text = target.innerText.trim();
                    if (text.length === 0)
                    {
                        return;
                    }
                    slot.value = text;
                }
                
                // Create a new target at the index after idx
                this.slots.splice(idx + 1, 0, new TicketReplyEditorSlot());
                await this.$nextTick();

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newTarget = (this.$refs['editor-slot'] as any)[idx + 1];
                if (newTarget)
                {
                    (newTarget as HTMLDivElement).focus();
                }
                else
                {
                    console.error('Failed to focus new slot');
                    alert('Failed to focus new slot');
                }
                this.emitInput();
                return;
            }
            const target = input.target as HTMLDivElement;
            const slot = this.slots[this.currentSlot];
            if (slot.type === TicketReplyEditorSlotType.Text)
            {
                slot.value = target.innerText;
            }
            this.emitInput();
        }
        finally
        {
            this.syncEditor();
        }
    }

    syncEditor()
    {
        for(let i = 0; i < this.slots.length; ++i)
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tg = (this.$refs['editor-slot'] as any)[i] as HTMLInputElement;
            if (tg)
            {
                let oldCursor: Range | undefined = undefined;
                
                // If the target is focused, save off the old cursor position
                if (document.activeElement === tg)
                {
                    oldCursor = window.getSelection()?.getRangeAt(0);
                    if (oldCursor)
                    {
                        alert(`Saved cursor position ${oldCursor.startOffset} - ${oldCursor.endOffset}`);
                    }
                }
                
                tg.innerHTML = this.slots[i].toEditorHtml();

                if (oldCursor !== undefined)
                {
                    tg.focus();

                    // Restore cursor position
                    const newCursor = document.createRange();
                    newCursor.setStart(tg, oldCursor.startOffset);
                    newCursor.setEnd(tg, oldCursor.endOffset);
                    window.getSelection()?.removeAllRanges();
                    window.getSelection()?.addRange(newCursor);

                    alert(`Restored cursor position to ${newCursor.startOffset} - ${newCursor.endOffset}`);
                }
            }
        }
        this.$forceUpdate();
    }

    currentSlot = 0;
    slots: TicketReplyEditorSlot[] = [new TicketReplyEditorSlot()];

    get html()
    {
        return this.slots.map(x => x.toOutputHtml()).join('\n');
    }
}
