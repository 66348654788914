
import { Component, Vue } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';
import LoginView from '@/views/auth/LoginView.vue';

@Component({
    components: { LoginView }
})
export default class App extends Vue
{
    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            await auth.init();
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }
}
