
import Vue from 'vue';
import Component from 'vue-class-component';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Emit, Prop, Watch } from 'vue-property-decorator';
// import { AxiosResponse } from 'axios';

@Component
export default class SupportTicketAttachments extends Vue
{
    unfilteredAttachments: (File | null)[] = [];

    MaxTotalSize = 7 * 1024 * 1024;
    
    removeAttachment(index: number)
    {
        this.unfilteredAttachments.splice(index, 1);
    }

    get valid()
    {
        return this.attachments.length == 0 || this.totalSize < this.MaxTotalSize;
    }

    get attachments(): File[]
    {
        return this.unfilteredAttachments.filter((attachment) => attachment !== null) as File[];
    }

    get totalSize()
    {
        return this.attachments.reduce((total, attachment) => total + attachment.size, 0);
    }

    addAttachment()
    {
        this.unfilteredAttachments.push(null);
    }

    displaySize(file: File)
    {
        const size = file.size;
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    }

    displaySizeNum(size: number)
    {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    }


    formatNames(files: File[])
    {
        return files.map((file) => `${file.name} (${this.displaySize(file)})`).join(', ');
    }
}
