
import Vue from 'vue';
import Component from 'vue-class-component';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Emit, Prop, Watch } from 'vue-property-decorator';
// import { AxiosResponse } from 'axios';
import copy from 'copy-to-clipboard';
import JsonViewer from 'vue-json-viewer';
import support, { Ticket } from '@/store/modules/SupportModule';
import SupportTicketReplyEditor from './SupportTicketReplyEditor.vue';
import SupportTicketAttachments from './SupportTicketAttachments.vue';
import permissionsModule from '@/store/modules/PermissionsModule';

function computeEmailListFromString(listUntrimmed: string) 
{
    const list = listUntrimmed.trim();
    if (!list || list.length === 0) return [''];

    const separated = list.split(',');
    if (separated.length === 0) return [''];
    return separated;
}

@Component(
    {
        components:
        {
            JsonViewer,
            SupportTicketReplyEditor,
            SupportTicketAttachments
        }
    }
)
export default class SupportTicketView extends Vue
{
    @Prop(String) dbid!: string;
    @Prop(String) id!: string;

    get idDisplay()
    {
        if (this.ticket) return `#${Ticket.ticketDisplayId(this.ticket)}`;
        return '[unknown]';
    }

    ticket: Ticket | null = null;
    noteInput = '';
    messageInput = '';
    deleteModalOpen = false;
    showingExpandedMessageModal = false;

    editingDescription = false;
    currentDescription = '';

    editingSubject = false;
    currentSubject = '';

    editingContactEmail = false;
    currentContactEmail = '';

    rawAssigneeOptions: { _id: string; firstName: string; lastName: string; email: string }[] = [];

    get assigneeOptions()
    {
        const res: { value: string | null; text: string }[] = [{
            value: null,
            text: 'Unassigned'
        }];

        res.push(...this.rawAssigneeOptions.map(a=>({ value: a._id, text: `${a.firstName} ${a.lastName} (${a.email})` })));
        return res;
    }

    get permissions()
    {
        return permissionsModule;
    }

    get canModifyTicket()
    {
        return permissionsModule.canModifyAnyTickets;
    }

    get contactEmailPreviewString()
    {
        if (!this.ticket) return '';
        let source = this.ticket.contactEmail;
        if (this.editingContactEmail)
        {
            source = this.currentContactEmail;
        }
        if (!source) return '';

        const list = computeEmailListFromString(source);
        if (list.length === 0) return '';
        return list.join(', ');
    }

    targetDatabase: string | null = null;
    rawDatabaseOptions: {name: string; visible: boolean}[] = [];
    get databaseOptions(): { value: string | null; text: string; disabled?: boolean }[]
    {
        const options = this.rawDatabaseOptions.map(a=>
        {
            // Kinda gross override, but AJ wanted it to display as SCS for demoing purposes and
            // this is by _far_ the most straightforward way to support that.
            const txt = a.name === 'aus' ? 'SCS' : a.name;
            const res = { value: a.name, text: txt, disabled: a.name === this.dbid || !a.visible };
            if (a.name === this.dbid) res.text += ' (current)';
            return res;
        });
        return [{value: null, text: 'None'}, ...options];
    }

    async getDatabases() 
    {
        try
        {
            this.rawDatabaseOptions = await support.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.',
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
    }

    async moveTicket()
    {
        if (!this.ticket?._id || !this.targetDatabase)
        {
            this.$bvToast.toast(
                'Failed to move ticket: missing ticket ID or target database.',
                {
                    title: 'Cannot move ticket!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
            return;
        }
        if (await support.moveTicket({ dbIdentifier: this.dbid, _id: this.ticket?._id, targetDbIdentifier: this.targetDatabase }))
        {
            this.$emit('deleted');
        }
    }

    editDescription()
    {
        this.editingDescription = true;
        this.currentDescription = this.ticket?.description || '';
    }

    editSubject()
    {
        this.editingSubject = true;
        this.currentSubject = this.ticket?.subject || '';
    }

    editContactEmail()
    {
        this.editingContactEmail = true;
        this.currentContactEmail = this.ticket?.contactEmail || '';
    }

    async saveTicketAssignment()
    {
        if (!this.ticket) return;
        
        const ticket = this.ticket;
        console.log({ticket});
        await support.updateTicketAssignment({ dbIdentifier: this.dbid, _id: this.ticket._id, assignee: this.ticket.assignee as string });
        await this.refresh();
        this.$emit('ticket-updated');
    }

    async saveDescription()
    {
        if (!this.ticket) return;
        if (!this.currentDescription) return;

        await support.updateDescription({ dbIdentifier: this.dbid, _id: this.ticket._id, description: this.currentDescription });
        this.editingDescription = false;
        await this.refresh();
        this.$emit('ticket-updated');
    }

    async saveSubject()
    {
        if (!this.ticket) return;
        if (!this.currentSubject) return;

        await support.updateSubject({ dbIdentifier: this.dbid, _id: this.ticket._id, subject: this.currentSubject });
        this.editingSubject = false;
        await this.refresh();
        this.$emit('ticket-updated');
    }

    async saveContactEmail()
    {
        if (!this.ticket) return;
        if (!this.currentContactEmail) return;

        await support.updateContactEmail({ dbIdentifier: this.dbid, _id: this.ticket._id, contactEmail: this.currentContactEmail });
        this.editingContactEmail = false;
        await this.refresh();
        this.$emit('ticket-updated');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    convertDateString(str: any)
    {
        return Ticket.convertDateToLocaleString(str);
    }

    get creationDateString()
    {
        if (!this.ticket || !this.ticket.history || this.ticket.history.length < 1) return 'No ticket history';
        return Ticket.convertDateToLocaleString(this.ticket.history[0].date);
    }

    get creatorName()
    {
        if (!this.ticket || !this.ticket.creator) return '';

        return `${this.ticket.creator.firstName} ${this.ticket.creator.lastName}`;
    }

    get contactEmail()
    {
        if (!this.ticket) return '';

        return this.ticket.contactEmail || this.ticket.creator?.email;
    }

    get state()
    {
        if (!this.ticket) return null;
        return JSON.parse(this.ticket.vuex);
    }

    get currentStatus()
    {
        return Ticket.status(this.ticket);
    }

    get deleteTime()
    {
        const date = Ticket.getDeletionTime(this.ticket);
        if (date) return Ticket.convertDateToLocaleString(date);
        return '[not marked for deletion]';
    }

    get isOpen()
    {
        return Ticket.isOpen(this.ticket);
    }

    get isInProgress()
    {
        console.log(this.ticket);
        return Ticket.isInProgress(this.ticket);
    }

    get isClosed()
    {
        return Ticket.isClosed(this.ticket);
    }

    get isMarkedForDeletion()
    {
        return Ticket.isMarkedForDeletion(this.ticket);
    }

    get ticketHistory()
    {
        let history = '';
        if (this.ticket && this.ticket.history && this.ticket.history.length > 0)
        {
            for (let i = this.ticket.history.length - 1; i >= 0; --i)
            {
                if (i < this.ticket.history.length - 1) history += '\n';
                const historyEntry = this.ticket.history[i];
                const statusColor = historyEntry.status === 'marked for deletion' ? 'darkcyan' : historyEntry.status === 'open' ? 'darkgreen' : historyEntry.status === 'in progress' ? 'darkorange' : 'darkred';
                history += `On <span style="font-weight: bold; color: darkred;">${
                    Ticket.convertDateToLocaleString(historyEntry.date)}</span>, <span style="font-weight: bold">${
                    Ticket.userToText(historyEntry.changedBy)}</span> changed status to <span style="font-weight: bold; color: ${statusColor}">${
                    historyEntry.status}</span>`;
            }
        }
        return history;
    }

    get ticketAssociatedEmails()
    {
        if (this.ticket && this.ticket.associatedEmails)
        {
            return this.ticket.associatedEmails;
        }
        return [];
    }

    get ticketNotes()
    {
        let notes = '';
        if (this.ticket && this.ticket.notes && this.ticket.notes.length > 0)
        {
            for (let i = this.ticket.notes.length - 1; i >= 0; --i)
            {
                if (i < this.ticket.notes.length - 1) notes += '\n';
                const notesEntry = this.ticket.notes[i];
                notes += `On ${
                    Ticket.convertDateToLocaleString(notesEntry.date)}, ${
                    Ticket.userToText(notesEntry.author)} wrote:\n${
                    notesEntry.text}`;
            }
        }
        return notes;
    }

    loading = false;
    async mounted()
    {
        await this.refresh();
    }

    async setStatus(status: string)
    {
        const history = await support.setTicketStatus({ dbIdentifier: this.dbid, _id: this.id, status });
        if (this.ticket != null && history != null)
        {
            this.$set(this.ticket, 'history', history);
        }
        else
        {
            alert(`Failed to update support ticket status`);
        }
    }

    get isMessageFormValid()
    {
        if (this.messageInput.length === 0) return false;
        
        const attachments = (this.$refs.attachments as SupportTicketAttachments);
        if (!attachments.valid) return false;
        return true;
    }

    isSending = false;
    async sendMessage()
    {
        this.isSending = true;
        try
        {
            const associatedEmails = await support.sendTicketMessage({ 
                _id: this.id,
                dbIdentifier: this.dbid, 
                message: this.messageInput,
                attachments: (this.$refs.attachments as SupportTicketAttachments).attachments 
            });

            if (this.ticket != null && associatedEmails != null)
            {
                this.$set(this.ticket, 'associatedEmails', associatedEmails);
                this.messageInput = '';
            }
            else
            {
                alert(`Failed to send support ticket message`);
            }
        }
        finally
        {
            this.isSending = false;
        }
        await this.refresh();
    }

    async addNote()
    {
        const notes = await support.addTicketNotes({ dbIdentifier: this.dbid, _id: this.id, notes: this.noteInput });
        if (this.ticket != null && notes != null)
        {
            this.$set(this.ticket, 'notes', notes);
            this.noteInput = '';
        }
        else
        {
            alert(`Failed to update support ticket notes`);
        }
    }

    generateSubjectLine()
    {
        return `RedmerU Support Request for user ${this.creatorName || this.contactEmail}`;
    }

    getMailToLink(email: string | undefined)
    {
        if (!this.ticket) return;

        if (!email)
        {
            email = this.contactEmail;
        }
        return `mailto:${
            email}?subject=${
            encodeURI(this.generateSubjectLine())}&body=${
            encodeURI(`Hello,\n\nYou recently submitted a support request with the following message:\n"${
                this.ticket.description}"\n\n[Support Agent: Add Reply Here]\n\n`)}`;
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const assigneeWait = support.getAgents({ dbIdentifier: this.dbid }).then(agents=>
            {
                this.rawAssigneeOptions.length = 0;
                this.rawAssigneeOptions.push(...agents);
            });

            const dbwait = this.getDatabases();
            const ticket = await support.getDatabaseTicket({ dbIdentifier: this.dbid, _id: this.id });
            if (ticket)
            {
                ticket.assignee = ticket.assignee || null;
            }
            this.$set(this, 'ticket', ticket);
            await Promise.all([dbwait, assigneeWait]);
        }
        catch (err)
        {
            // const resp = err.response as AxiosResponse | undefined;
            alert(`Failed to load support ticket`);
        }
        this.loading = false;

        await this.$nextTick();
        if (this.$refs.messageContainer)
        {
            (this.$refs.messageContainer as HTMLDivElement).scrollTop = (this.$refs.messageContainer as HTMLDivElement).scrollHeight;
        }
    }

    async deleteTicket()
    {
        this.loading = true;
        try
        {
            await support.deleteDatabaseTicket({ dbIdentifier: this.dbid, _id: this.id });
            if (this.$router.currentRoute.name === 'Support Ticket')
            {
                this.$router.push('/?tab=3');
                this.$router.go(0);
            }
            this.$emit('deleted');
        }
        catch (err)
        {
            // const resp = err.response as AxiosResponse | undefined;
            alert(`Failed to delete support ticket`);
        }
        this.loading = false;
    }

    copy(text: string)
    {
        copy(text);
    }

    copyLink()
    {
        copy(`${window.location.origin}/ticket?dbid=${this.dbid}&id=${this.id}`);
    }
}
