
import { Component, Vue } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';

@Component
export default class LoginView extends Vue
{
    form = {
        email: '',
        password: ''
    }

    loading = false;
    async login() 
    {
        this.loading = true;
        try
        {
            await auth.login(this.form);
        }
        catch(err)
        {
            console.error(err);
            alert('Log in failed.  Please verify that your email and password are correct and verify that you have permission to access this tool.');
        }
        finally
        {
            this.loading = false;
        }
    }
}
