
import { Component, Vue, Watch } from 'vue-property-decorator';
import support, { Ticket } from '@/store/modules/SupportModule';
import SupportTicket from './SupportTicket.vue';
import { nextTick } from 'vue';

type TicketRow = { dbid: string } & Ticket;

@Component({
    components:
    {
        SupportTicket,
    }
})
export default class SupportTickets extends Vue
{
    setTimeout(a: Function, b: number)
    {
        return setTimeout(a, b);
    }

    get assigneeFilterOptions()
    {
        const set = new Map<string, ({ firstName: string; lastName: string; email: string; _id: string })>();
        for(const dbid of this.selectedDatabases)
        {
            const agents = this.loadedAgentsByDbId.get(dbid);
            if (agents)
            {
                for(const agent of agents)
                {
                    if (!set.has(agent.email))
                    {
                        set.set(agent.email, agent);
                    }
                }
            }
        }
        return Array.from(set.values());
    }

    selectedFilterAssignee: string | null = 'any-assignee';
    onlyShowAssigned = false;

    wasTicketUpdated = false;
    rawDatabaseOptions: {name: string; visible: boolean}[] = [];
    get databaseOptions()
    {
        return this.rawDatabaseOptions.map(a=>
        {
            // Kinda gross override, but AJ wanted it to display as SCS for demoing purposes and
            // this is by _far_ the most straightforward way to support that.
            if (a.name === 'aus')
            {
                return { value: a.name, text: 'SCS', disabled: !a.visible };
            }
            return { value: a.name, text: a.name, disabled: !a.visible };
        });
    }
    // singleSourceDbId = '';
    
    selectedDatabases: string[] = [];
    
    @Watch('selectedDatabases', { deep: true })
    async onSelectedDatabasesChanged()
    {
        await this.refresh();
    }

    @Watch('onlyShowAssigned')
    async onOnlyShowAssignedChanged()
    {
        await this.refresh();
    }
    
    
    externalSourceOptions: string[] = [];
    externalSource = '';
    
    loading = false;
    
    tab = 0;
    filter = '';
    stateFilter = '';
    sourceUrl = '/unresolved';

    @Watch('sourceUrl')
    async onSourceUrlChanged()
    {
        await this.refresh();
    }

    stateFilterUpdateId = 0;
    stateFilterNextUpdateTime = Date.now();
    stateFilterIsUpdating = false;
    stateFilterUpdateCancelled = false;
    
    @Watch('stateFilter')
    async onStateFilterChanged()
    {
        const updateId = ++this.stateFilterUpdateId;
        if (this.stateFilterIsUpdating)
        {
            this.stateFilterUpdateCancelled = true;
            return;
        }
        else if (this.stateFilterNextUpdateTime > Date.now())
        {
            this.stateFilterUpdateId = updateId;
            setTimeout(()=>
            {
                if (this.stateFilterUpdateId === updateId)
                {
                    this.stateFilterNextUpdateTime = Date.now() + 500;
                    this.onStateFilterChanged();
                }
            }, Math.min(this.stateFilterNextUpdateTime - Date.now(), 100));
            return;
        }

        this.stateFilterIsUpdating = true;
        try
        {
            await this.refresh(true);

            // Wait until the next tick to make sure the filter input ref exists
            await nextTick();
    
            const filter = this.$refs.stateFilterInput as HTMLInputElement;
            if (filter)
            {
                filter.focus();
            }

            if (this.stateFilterUpdateCancelled)
            {
                this.stateFilterUpdateCancelled = false;
                this.onStateFilterChanged();
            }
        }
        finally
        {
            this.stateFilterIsUpdating = false;
        }
    }

    ticketList: TicketRow[] = [];

    showTicket = false;

    selectedTicketDbId?: string | null = null;
    selectedTicketId?: string | null = null;

    async getDatabases() 
    {
        this.loading = true;
        try
        {
            this.rawDatabaseOptions = await support.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.',
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    // Maps database IDs to a list of agents
    rawLoadedAgentsByDbId: Map<string, ({ firstName: string; lastName: string; email: string; _id: string })[]> = new Map();
    agentFlip = false;

    get loadedAgentsByDbId()
    {
        if (this.agentFlip || !this.agentFlip)
        {
            return this.rawLoadedAgentsByDbId;
        }
        return this.rawLoadedAgentsByDbId;
    }
    
    async loadAgents()
    {
        this.loading = true;
        try
        {
            const promises = [];
            for(const dbid of this.rawDatabaseOptions)
            {
                console.log(`loading agents for ${dbid}`);
                const promise = async()=>
                {
                    try
                    {
                        console.log(`(promise) loading agents for ${dbid}`);
                        const result = await support.getAgents({ dbIdentifier: dbid.name });
                        console.log(`(promise) loaded agents for ${dbid}: ${result}`);
                        this.rawLoadedAgentsByDbId.set(dbid.name, result);
                    }
                    catch(err)
                    {
                        console.error(err);
                    // this.$bvToast.toast(
                    //     'Verify you have sufficient permissions and refresh.',
                    //     {
                    //         title: 'Cannot load agents!',
                    //         autoHideDelay: 9000,
                    //         variant: 'danger'
                    //     }
                    // );
                    }
                };
                promises.push(promise());
            }
            await Promise.all(promises);
        }
        catch(err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.',
                {
                    title: 'Cannot load agents!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
            this.agentFlip = !this.agentFlip;
        }
    }

    async getTickets(dbId: string, onlyShowAssigned: boolean): Promise<TicketRow[] | undefined>
    {
        return (await support.getDatabaseTickets({ onlyShowAssigned: onlyShowAssigned,  dbIdentifier: dbId, modifier: this.sourceUrl, stateFilter: this.stateFilter })).map(ticket=>({...ticket, dbid: dbId}));
    }

    get tableFields()
    {
        const fields = [
            // {
            //     key: '_id',
            //     label: 'ID',
            //     sortable: true,
            // },
            {
                key: 'ticketId',
                label: 'ID',
                sortable: true,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return Ticket.ticketDisplayId(item);
                }
            },
            {
                key: 'creator',
                label: 'Creator',
                sortable: true,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return item.creator ? `${item.creator.firstName} ${item.creator.lastName}`.trim() : '[No User]';
                },
                thStyle:
                {
                    width: '8%', // add the fixed param to the b-table to enable these fixed percentage widths
                },
            },
            {
                key: 'contactEmail',
                label: 'Email',
                sortable: true,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return value ? value : (item.creator ? item.creator.email : '');
                },
                thStyle:
                {
                    width: '13%',
                },
                tdClass: 'text-left',
            },
            {
                key: 'subject',
                label: 'Subject',
                sortable: true,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    if (value && value.length > 200)
                    {
                        return value.substring(0, 200).trim() + '...';
                    }
                    return value;
                },
                thStyle:
                {
                    width: '17%',
                },
                tdClass: 'text-left',
            },
            { 
                key: 'description',
                label: 'Description',
                sortable: true,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    if (value.length > 200)
                    {
                        return value.substring(0, 200).trim() + '...';
                    }
                    return value;
                },
                thStyle:
                {
                    width: '33%',
                    'max-width': '200px'
                },
                tdClass: 'text-left ticket-description-td-wrap',
            },
            {
                key: 'assignee',
                label: 'Assignee',
                sortable: true,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return item.assignee ? `${item.assignee.firstName} ${item.assignee.lastName} (${item.assignee.email})`.trim() : 'Unassigned';
                },
                thStyle:
                {
                    width: '20%', // add the fixed param to the b-table to enable these fixed percentage widths
                },
            },
            {
                key: 'history',
                label: 'Status',
                sortable: true,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return Ticket.status(item);
                },
                thStyle:
                {
                    width: '6.5%',
                },
            },
            {
                key: 'dbid',
                label: 'Database',
                sortable: true,
                formatter: (value: string) =>
                {
                    return value ? value : "[Unknown]";
                },
                thStyle:
                {
                    width: '8%',
                },
            },
            {
                key: 'externalSource',
                label: 'Source',
                sortable: true,
                formatter: (value: string) =>
                {
                    return value ? value : "Internal";
                },
                thStyle:
                {
                    width: '7%',
                },
            },
            {
                key: 'updated',
                sortable: true,
                sortByFormatted: false,
                formatter: (value: string, key: string, item: Ticket) =>
                {
                    return this.computeDateString(item.updated);
                },
                thStyle:
                {
                    width: '7.5%',
                },
            },
        ];

        // if (this.multiSelect)
        // {
        //     fields.push({key: 'selected', sortable: false});
        // }

        return fields;
    }

    computeDateString(date: Date)
    {
        return Ticket.convertDateToLocaleString(date);
    }

    isSelectingFromDropdown = false;
    dropdownFixCounter = 0;
    rowClicked(item: TicketRow)
    {
        if (this.isSelectingFromDropdown) return;
        // if (this.multiSelect) return;
        // this.$router.push(`/tickets/${item.id}`);
        console.log(item);
        this.selectedTicketId = item._id;
        this.selectedTicketDbId = item.dbid;
        this.showTicket = true;
    }

    get formattedTickets()
    {
        // Added this check to solve reactivity bug on refresh
        if (this.loading && this.forceDisplayWhileLoading === 0) return [];

        let intermediate = this.ticketList;
        if (this.externalSource === "Internal")
        {
            intermediate = intermediate.filter((a) => !(a.externalSource));
        }
        else if (this.externalSource)
        {
            intermediate = intermediate.filter((a) =>
                a.externalSource && a.externalSource === this.externalSource
            );
        }

        // intermediate = intermediate.filter((a)=>
        // {
        //     // Filter on status
        //     if (this.sourceUrl === '/unresolved')
        //     {
        //         return !Ticket.isClosed(a);
        //     }
        //     else if (this.sourceUrl === '/resolved')
        //     {
        //         return Ticket.isClosed(a);
        //     }
        // });

        const lowered = this.filter.toLowerCase();
        if (lowered.trim() !== '')
        {
            intermediate = intermediate.filter((a) =>
                a.description.toLowerCase().includes(lowered)
                || (a.subject && a.subject.toLowerCase().includes(lowered))
                || (a.creator && a.creator.email.toLowerCase().includes(lowered))
                || (a.creator && `${a.creator.firstName} ${a.creator.lastName}`.toLowerCase().includes(lowered))
                || (a && Ticket.ticketDisplayId(a).toLowerCase().includes(lowered))
            );
        }

        if (!this.onlyShowAssigned)
        {
            if (this.selectedFilterAssignee === null)
            {
                intermediate = intermediate.filter((a) => !a.assignee);
            }
            else if (this.selectedFilterAssignee !== 'any-assignee')
            {
                intermediate = intermediate.filter((a) => a.assignee && a.assignee.email === this.selectedFilterAssignee);
            }
        }

        // const stateTrimmed = this.stateFilter.trim();
        // if (stateTrimmed !== '')
        // {
        //     const regex = new RegExp(stateTrimmed, 'gi');
        //     intermediate = intermediate.filter((a) => regex.test(a.vuex));
        // }

        return intermediate.map((a) =>
        {
            const temp =
                Object.assign(
                    {
                        _rowVariant:  (Ticket.isMarkedForDeletion(a)) ? 'info' : ( (Ticket.isClosed(a)) ? 'success' : (Ticket.isInProgress(a) ? 'warning' : 'danger')),
                        selected: false
                    }, a);
            return temp;
        });
    }

    forceDisplayWhileLoading = 0;
    async refresh(forceDisplayWhileLoading = false)
    {
        if (this.loading) return;

        await this.loadAgents();

        if (forceDisplayWhileLoading)
        {
            // alert('inc');
            this.forceDisplayWhileLoading++;
        }
        this.loading = true;

        try
        {
            try
            {
                const ticketSourceAwaiters = [];
                const awaiters = [];
                for(const dbid of this.selectedDatabases)
                {
                    awaiters.push(this.getTickets(dbid, this.onlyShowAssigned));
                    ticketSourceAwaiters.push(support.getDatabaseTicketSources({ dbIdentifier: dbid }));
                }
                
                const ticketSources = (await Promise.all(ticketSourceAwaiters));
                const ticketSourceDedupSet = new Set<string>();
                for(const ticketSource of ticketSources)
                {
                    for(const source of ticketSource)
                    {
                        if (source && !ticketSourceDedupSet.has(source))
                            ticketSourceDedupSet.add(source);
                    }
                }
            
                this.externalSourceOptions.length = 0;
                if (ticketSourceDedupSet.size > 0)
                {
                    for(const source of ticketSourceDedupSet)
                    {
                        this.externalSourceOptions.push(source);
                    }
                    
                    this.externalSourceOptions.unshift('Internal');
                }
                
                console.log(`pre push - currently ${this.ticketList.length} elements`);
                // this.ticketList = [
                //     {
                //         _id: '1',
                //         dbid: '1',
                //         description: 'test',
                //         updated: new Date(),
                //         creator: {
                //             email: 'asdf@asdf.asdf',
                //             firstName: 'asdf',
                //             lastName: 'asdf',
                //         },
                //         associatedEmails: [],
                //         externalSource: 'asdf',
                //         history: [],
                //         subject: '',
                //         contactEmail: '',
                //         createdFromEmail: false,
                //         imageUrl: '',
                //         notes: [],
                //         url: '',
                //         vuex: ''
                //     }
                // ];
                
                const ticketResults = (await Promise.all(awaiters));
                this.ticketList.length = 0;
                for(const results of ticketResults)
                {
                    if (results)
                    {
                        for(const ticket of results)
                        {
                            this.ticketList.push(ticket);
                            console.log(`pushing ticket ${ticket.subject} (len ${this.ticketList.length})`);
                        }
                    }
                }
                console.log(`post push - currently ${this.ticketList.length} elements`);
            }
            catch (err)
            {
                alert(`Failed to load support tickets`);
            }
        }
        finally
        {
            if (forceDisplayWhileLoading)
            {
                // alert('dec');
                this.forceDisplayWhileLoading--;
            }
            this.loading = false;
        }
    }

    async updateAssignee(ticket: TicketRow, assignee: string | null)
    {
        try
        {
            await support.updateTicketAssignment({ dbIdentifier: ticket.dbid, _id: ticket._id, assignee: assignee as string });
        }
        catch(err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and try again.',
                {
                    title: 'Cannot update ticket assignment!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
            ++this.dropdownFixCounter;
        }
    }

    async updateStatus(ticket: TicketRow, status: string)
    {
        try
        {
            // Update ticket.history based on the new history
            const updatedHistory = await support.setTicketStatus({ dbIdentifier: ticket.dbid, _id: ticket._id, status });
            
            const targetTicket = this.ticketList.find(a=>a._id === ticket._id);
            if (targetTicket)
            {
                console.log(Object.assign({}, targetTicket.history));
                this.$set(targetTicket, 'history', updatedHistory);
                console.log(Object.assign({}, targetTicket.history));
            }
            else
            {
                alert('Failed to update ticket status');
            }
        }
        catch(err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and try again.',
                {
                    title: 'Cannot update ticket assignment!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        ++this.dropdownFixCounter;
    }

    async mounted()
    {
        await this.getDatabases();
        await this.refresh();
    }
}
