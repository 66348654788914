import * as Axios from 'axios';
import env from './Environment';
import auth from '@/store/modules/AuthModule';

export const options: Axios.AxiosRequestConfig = {
    baseURL: env.API,
};

export const postProcessInstance = (axios: Axios.AxiosInstance) => 
{
    axios.interceptors.request.use((cfg) => 
    {
        if (auth.authToken) 
        {
            cfg.headers['x-auth'] = auth.authToken;
        }
        return cfg;
    });

    let hasNotifiedOfAuthFailure = false;
    const handleAuth = (response: Axios.AxiosResponse) => 
    {
        if (response.headers['x-auth']) 
        {
            if (response.headers['x-auth'] === 'invalid')
            {
                if (!hasNotifiedOfAuthFailure)
                {
                    alert('Your login has expired or is otherwise invalid.  Please sign back in.');
                    hasNotifiedOfAuthFailure = true;
                }
                auth.setAuthToken(null);
                window.location.reload();
                return;
            }
            auth.setAuthToken(response.headers['x-auth']);
        }
        return response;
    };

    axios.interceptors.response.use(handleAuth, (err)=>
    {
        if (err.response)
        {
            handleAuth(err.response);
        }
    });
};