
import Vue from 'vue';
import Component from 'vue-class-component';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Emit, Prop, Watch } from 'vue-property-decorator';
// import { AxiosResponse } from 'axios';
import copy from 'copy-to-clipboard';
import User from '@/models/User';
import userModule from '@/store/modules/UserModule';
import { AxiosResponse } from 'axios';

@Component
export default class UserManage extends Vue
{
    @Prop(String) dbid!: string;
    @Prop(String) id!: string;
    user: User | null = null;

    get disableCopyButton()
    {
        return this.loading || !this.targetDatabase || this.copying;
    }

    get disableDeleteButton()
    {
        return !this.allowDelete || this.deleting;
    }

    targetDatabase: string | null = null;
    rawDatabaseOptions: string[] = [];
    get databaseOptions(): { value: string | null; text: string; disabled?: boolean }[]
    {
        const options = this.rawDatabaseOptions.map(value=>
        {
            // Kinda gross override, but AJ wanted it to display as SCS for demoing purposes and
            // this is by _far_ the most straightforward way to support that.
            const txt = value;
            const res = { value: value, text: txt, disabled: value === this.dbid };
            if (res.disabled) res.text += ' (current)';
            return res;
        });
        return [{value: null, text: 'None'}, ...options];
    }

    async getDatabases() 
    {
        try
        {
            this.rawDatabaseOptions = await userModule.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.',
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
    }

    copying = false;
    loading = false;
    allowDelete = false;
    async mounted()
    {
        await this.refresh();
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const dbwait = this.getDatabases();
            const user = await userModule.getUser({ dbIdentifier: this.dbid, userId: this.id });
            this.$set(this, 'user', user);
            await dbwait;
        }
        catch (err)
        {
            // const resp = err.response as AxiosResponse | undefined;
            alert(`Failed to load user`);
        }
        this.loading = false;
    }

    async copyUserToTargetEnvironment()
    {
        if (!this.targetDatabase) 
        {
            alert('Please select a target database');
            return;
        }

        if (!this.user)
        {
            alert('Please select a user');
            return;
        }

        try
        {
            this.copying = true;
            await userModule.copyUser({
                sourceDbId: this.dbid,
                targetDbId: this.targetDatabase,
                userId: this.user._id
            });
            alert(`Successfully copied user to target environment`);
        }
        catch(errt)
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const err = errt as any;
            if (err.response)
            {
                const resp = err.response as AxiosResponse;
                if (resp.status === 409)
                {
                    alert(`Failed to copy user to target environment.  User already exists in target environment.`);
                    return;
                }
            }
            console.error(err);
            alert(`Failed to copy user to target environment.  See console for details.`);
        }
        this.copying = false;
    }

    deleting = false;
    async deleteUser()
    {
        if (!this.allowDelete)
        {
            alert('Please check the "Unlock Deletion" checkbox to enable deletion');
            return;
        }

        if (!this.user)
        {
            alert('Please select a user');
            return;
        }

        try
        {
            this.deleting = true;
            await userModule.deleteUser({
                dbIdentifier: this.dbid,
                userId: this.user._id
            });
            alert(`Successfully deleted user`);
            this.$emit('deleted');
        }
        catch(errt)
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const err = errt as any;
            if (err.response)
            {
                const resp = err.response as AxiosResponse;
                if (resp.status === 409)
                {
                    alert(`Failed to delete user.  User is a member of a workgroup and cannot be deleted.`);
                    return;
                }
            }
            console.error(err);
            alert(`Failed to delete user.  See console for details.`);
        }
        this.deleting = false;
    }

    copy(text: string)
    {
        copy(text);
    }
}
