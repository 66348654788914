import axios from '@/utils/axios';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import User from '@/models/User';

const authTokenKey = 'authoringDataManagerAuthToken';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class AuthModule extends VuexModule
{
    activeUser: User | null = null;
    authToken: string | null = null;

    get isSignedIn()
    {
        return this.activeUser != null;
    }

    @Mutation
    setActiveUser(user: User | null)
    {
        this.activeUser = user;
    }

    @Mutation
    setAuthToken(token: string | null)
    {
        if (token === 'invalid')
        {
            this.authToken = null;
        }
        else 
        {
            this.authToken = token;
        }

        if (this.authToken)
        {
            localStorage.setItem(authTokenKey, this.authToken);
        }
        else 
        {
            localStorage.removeItem(authTokenKey);
        }
    }

    @Action
    async init()
    {
        const loaded = localStorage.getItem(authTokenKey);
        if (loaded) 
        {
            this.setAuthToken(loaded);
            try
            {
                const resp = await axios.post<{ user: User }>('users/me');
                this.setActiveUser(resp.data.user);
            }
            catch(err)
            {
                console.error(err);
                this.setAuthToken(null);
                return;
            }
        }
    }

    @Action
    async login(form: { email: string; password: string })
    {
        const resp = await axios.post<{ user: User; token: string }>('users/login', form);
        this.setActiveUser(resp.data.user);
        this.setAuthToken(resp.data.token);
        return resp.data;
    }

    @Action
    async logout()
    {
        this.setActiveUser(null);
        this.setAuthToken(null);
        window.location.reload();
    }
}

// Register the module
import store from '../index';
const authModule = new AuthModule({store, name: 'auth'});
export default authModule;