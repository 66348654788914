
import { Component, Vue } from 'vue-property-decorator';
import deepcopy from '@/store/modules/DeepCopyModule';

@Component
export default class Home extends Vue
{
    databaseOptions: string[] = [];
    databaseCharacters: { _id: string; name: string; updated: Date }[] = [];

    form = {
        sourceDbId: '',
        characterId: '',
        targetDbId: '',
        characterIds: [] as string[],
    }

    loading = false;
    copying = false;

    tab = 0;
    filter = '';

    get formValid()
    {
        return this.form.sourceDbId.length > 0
            && (((this.tab === 0) && this.form.characterId.length > 0) 
                || ((this.tab === 1) && this.form.characterIds.length > 0))
            && this.form.targetDbId.length > 0;
    }

    get characterOptions()
    {
        return this.databaseCharacters
            .map((g) =>
            {
                return { 
                    value: g._id,
                    text: `${g.name} (${g.updated})`
                };
            })
            .filter(o => o.text.includes(this.filter))
            .sort((a, b) =>
            {
                return a.text.localeCompare(b.text);
            });
    }

    toggleAll()
    {
        if (this.form.characterIds.length)
        {
            this.form.characterIds = [];
        }
        else
        {
            this.form.characterIds = this.characterOptions.map(o => o.value);
        }
    }

    async getDatabases() 
    {
        this.loading = true;
        try
        {
            this.databaseOptions = await deepcopy.getDatabases();
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Cannot load database list!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async getCharacters() 
    {
        this.loading = true;
        this.form.characterId = '';
        try
        {
            this.databaseCharacters = await deepcopy.getDatabaseCharacters(this.form.sourceDbId);
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Cannot load character list for the selected database!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.loading = false;
        }
    }

    async copy() 
    {
        this.copying = true;
        try
        {
            await deepcopy.deepCopyCharacter(this.form);
            this.$bvToast.toast(
                `Copied the selected character from ${this.form.sourceDbId} to ${this.form.targetDbId}`,
                {
                    title: 'Copy completed!',
                    autoHideDelay: 9000,
                    variant: 'success'
                }
            );
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Copy failed!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.copying = false;
        }
    }

    async copyMultiple() 
    {
        this.copying = true;
        try
        {
            await deepcopy.deepCopyCharacters(this.form);
            this.$bvToast.toast(
                `Copied the ${this.form.characterIds.length} selected characters from ${this.form.sourceDbId} to ${this.form.targetDbId}`,
                {
                    title: 'Copy completed!',
                    autoHideDelay: 9000,
                    variant: 'success'
                }
            );
        }
        catch (err)
        {
            console.error(err);
            this.$bvToast.toast(
                'Verify you have sufficient permissions and refresh.', 
                {
                    title: 'Copy failed!',
                    autoHideDelay: 9000,
                    variant: 'danger'
                }
            );
        }
        finally
        {
            this.copying = false;
        }
    }

    mounted()
    {
        this.getDatabases();
    }
}
