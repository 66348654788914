import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class DeepCopyModule extends VuexModule
{
    @Action
    async getDatabases()
    {
        try
        {
            const resp = await axios.get<{ databases: string[] }>('databases');
            return resp.data.databases;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async getDatabaseGames(dbIdentifier: string)
    {
        try
        {
            const resp = await axios.get<{ games: { _id: string; name: string; updated: Date }[] }>(`database/${dbIdentifier}/games`);
            return resp.data.games;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async deepCopyGame(form: { sourceDbId: string; gameId: string; targetDbId: string })
    {
        const resp = await axios.post(`database/${form.sourceDbId}/game/${form.gameId}/deepcopy/${form.targetDbId}`);
        return resp.data;
    }

    @Action
    async getDatabaseCharacters(dbIdentifier: string)
    {
        try
        {
            const resp = await axios.get<{ characters: { _id: string; name: string; updated: Date }[] }>(`database/${dbIdentifier}/characters`);
            return resp.data.characters;
        }
        catch (err)
        {
            console.error(err);
            return [];
        }
    }

    @Action
    async deepCopyCharacter(form: { sourceDbId: string; characterId: string; targetDbId: string })
    {
        const resp = await axios.post(`database/${form.sourceDbId}/character/${form.characterId}/deepcopy/${form.targetDbId}`);
        return resp.data;
    }

    @Action
    async deepCopyCharacters(form: { sourceDbId: string; characterIds: string[]; targetDbId: string })
    {
        const resp = await axios.post(
            `database/${form.sourceDbId}/characters/deepcopy/${form.targetDbId}`,
            { characterIds: form.characterIds }
        );
        return resp.data;
    }
}

// Register the module
import store from '../index';
const deepCopyModule = new DeepCopyModule({store, name: 'deepcopy'});
export default deepCopyModule;